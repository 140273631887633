<template>
  <div class="container justify-content-center">
  <div v-if="u_rows">
    <h2>{{(org && org.name) || "Administrateur"}}</h2>

    <h4>Utilisateurs</h4>
    <!--org_id={{org_id}}-->
    Groupe : '{{user.roles[0].group.name}}'

    <Radar v-if="rdata.labels" style="height: 75vh; width: 75vh" :chart-data="rdata" :chart-options="roptions"/>

<!--
        <vue-good-table styleClass="vgt-table condensed striped"  :columns="u_columns" :rows="u_rows">
            <template slot="table-row" slot-scope="props">

                <div v-if="props.column.field == 'pseudo'">{{props.row.pseudo}}<br/><b>{{props.row.email}}</b></div>
                <div v-if="props.column.field == 'roles'">
                  <div v-for="(r,ir) in user.roles" :key="'x'+ir" >
                   <div v-if="r.organisation_id==org_id">
                    <input type="checkbox" @change="roleInputChange" :id="props.row._id+'-'+r.role+'-'+r.organisation_id+'-'+r.group_id" v-model="checked[props.row._id+'-'+r.role+'-'+r.organisation_id+'-'+r.group_id]" :ref="props.row._id+'-'+r.role+'-'+r.organisation_id+'-'+r.group_id" />
                    {{rn[r.role]}} {{r.role==2 ? r.organisation.name : ''}}{{r.role==4 ? r.group.name : ''}}{{r.role==0 ? r.group.name : ''}}
                   </div>
                  </div>
                  <button type="button" @click="userUpd(props.row._id)" class="btn btn-outline-primary buttons">{{$t('save')}}</button><br/>
                </div>
                <div v-if="props.column.field == 'createdAt'">{{props.formattedRow[props.column.field].substring(0,10)}}</div>
                <div v-if="props.column.field == 'syntheses'">
                  <div v-for="(s,is) in props.row.syntheses" :key="'s'+is">
                  {{s.techno}} : <b>{{s.grade}}</b> (<span style='font-size:75%'>{{s.dt.substr(0,10) }}</span>)
                  </div>
                </div>

            </template>
        </vue-good-table>
-->
        <br/><br/>

  </div>
  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
//import ImageUpdater from '@/components/ImageUpdater.vue'
import { Radar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, PointElement, LineElement, CategoryScale, LinearScale, RadialLinearScale } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, PointElement, LineElement, CategoryScale, LinearScale, RadialLinearScale)

export default {
  data(){
      return {
        organisations: [],
        u_columns: [
            {label: this.$i18n.t('pseudo'), field: 'pseudo',},
            {label: this.$i18n.t('roles'), field: 'roles',},
            {label: this.$i18n.t('synthese'), field: 'syntheses',},
            //{label: this.$i18n.t('email'), field: 'email',},
            //{label: this.$i18n.t('isAdmin'), field: 'isAdmin',},
            //{label: this.$i18n.t('isAdmin'), field: 'isAdmin',},
            //{label: this.$i18n.t('organisation'), field: 'isOrga',},
            //{label: this.$i18n.t('Prof'), field: 'isProf',},
            {label: this.$i18n.t('createdAt'), field: 'createdAt',},
            //abel: this.$i18n.t('organisation'), field: 'organisation_id',},
            ],
        u_rows: [],
        org: null,
        checked: {},
        //rn: {'1': 'Administrareur'},
        rn: ['Elève', 'Administrateur', 'Directeur', '', 'Professeur', '', '', ''],

        users: [],
        rdata : {
                          labels: ['C1','C5','C7'],
                          datasets: [{
                            label: 'User1',
                            data: [0.5,5.0,2.5],
                            //fill: true,
                            //backgroundColor: 'rgba(255, 99, 132, 0.2)',
                            //borderColor: 'rgb(255, 99, 132)',
                            //pointBackgroundColor: 'rgb(255, 99, 132)',
                            //pointBorderColor: '#fff',
                            //pointHoverBackgroundColor: '#fff',
                            //pointHoverBorderColor: 'rgb(255, 99, 132)'
                          }, {
                            label:'user2',
                            data:[1,2,3],
                            backgroundColor: '#'+(Math.random()*0xFFFFFF<<0).toString(16),
                            borderColor: '#'+(Math.random()*0xFFFFFF<<0).toString(16),
                            }]
                        },
        roptions : {
                    //height: '500px',
                    responsive: true,
                    maintainAspectRatio: true,
                    scales:{r:{pointLabels:{font:{size: 20, color: '#880000'}}}},
                    scale: {min: 0.0, max: 5.0,
                        },
                    },
      }
  },
  name: "AdminUsers",
  props: ['user', 'org_id'],
  watch: {
    org_id: function () {
        this.orgChange()
    },
  },
  components: {
    Radar, //ImageUpdater,
  },
  methods: {
    userUpd(user_id) {
        //alert(user_id)
        let roles=[]
        for (let id in this.checked) {
            if (this.checked[id] && id.startsWith(user_id)) {
                let x = id.split('-')
                let r={role:x[1], organisation_id:x[2]}
                if (x[3]!='undefined') r.group_id=x[3]
                roles.push(r)
                console.log(' ROLE', id, x)
            }
        }
        MyFlashDS.userSave({_id: user_id, roles: roles})
        console.log(' ROLES', user_id, roles)
    },
    roleInputChange(evt) {
        //console.log(evt.target.id, this.checked[evt.target.id], evt.target.checked)
        this.checked[evt.target.id]=evt.target.checked;
        console.log('done', evt.target.id, this.checked[evt.target.id])
    },
    beforeUpd(users) {
        //console.log('+++rolesUpd+++')
        users.forEach(u=>{
          console.log('+++rolesUpd+++ user', u._id)
          u.roles.forEach(r=>{
            let id=u._id+'-'+r.role+'-'+r.organisation_id+'-'+r.group_id
            console.log('+++rolesUpd+++ role', id, this.$refs[id])
            //this.$refs[id]
            this.checked[id] = true
          })
        })
    },
    orgChange(row) {
      console.log('AdminUser.orgChange:', this.org_id, row);
      //console.log(row.id, row.organisation_id);
        MyFlashDS.organisationFindOne({id: this.org_id}).then(res=>{
            console.log(res)
            this.org=res.data;
            //this.$nextTick(this.rolesUpd);
            });
      //MyFlashDS.userSave({id: row.id, organisation_id:row.organisation_id})
    },
    doLoadUsers() {
        MyFlashDS.getAllUsers().then(res => {
            res.data.forEach(x=>{
              x.isAdmin=x.role&1;
              x.isOrga=x.role&2;
              x.isProf=x.role&4;
            });
            this.beforeUpd(res.data);
            this.u_rows=res.data;
            //this.$nextTick(this.rolesUpd);
        });
    },
    doUpdRole2(prop) { // OLD
      var role=(prop.isAdmin?1:0)+(prop.isOrga?2:0)+(prop.isProf?4:0);
      console.log('ROLE', {id:prop._id, role:role})
      MyFlashDS.saveUser({id:prop._id, role:role}).then(() => {
        this.doLoadUsers();
      });
    },
  },
  mounted() {
    // 2024-10
    MyFlashDS.getAllUsers().then(res => {
      console.log(res)
      this.users = res.data.filter(user=>{return user.roles[0].group_id==this.user.roles[0].group_id})

      MyFlashDS.answerGet({users:this.users.map(user=>user._id)}).then(res => {
        console.log(res.data)
        this.history=res.data
        let comps=new Set()
                this.history.forEach(a=>{
                  comps.add(a.question_id.competences[0])
                })
                comps=Array.from(comps).sort()
                this.rdata.labels=comps

      console.log('group ', this.user.roles[0].group_id)
      this.rdata.datasets = this.users.sort((a,b) => {return a.pseudo<b.pseudo})
        .map(user=>{
          console.log('user ', user.pseudo, user.roles[0].group_id, this.user.roles[0].group_id)

          let data = comps.map(comp=>{
                    let qs = this.history.filter(ans => ans.question_id.competences[0]==comp && ans.user_id==user._id)
                    let sum = qs.reduce((sum,ans) => {return sum+ans.grade}, 0)
                    console.log('  COMP', comp, 'sum=', sum, 'l=', qs.length)
                    return sum/qs.length
                  })


          let c = '#'+(Math.random()*0xFFFFFF<<0).toString(16)
          return {label: user.pseudo, data: data, backgroundColor: c, borderColor: c}
      })
      console.log('dataset', this.rdata.datasets)

      })


    })


/*
    this.doLoadUsers();
    this.orgChange();
*/
  },
}
</script>

<style scoped>

div.activated {color: darkgreen;}
div.inhibited {color: red;}

button {
  width: 20vh;
}

h4 {
  margin-top: 3vh;
}

img {
    max-width: 100px;
    max-height: 100px;
}

</style>
