<template>
  <div class="container justify-content-center">
    <h2>Gestion des questions</h2>

    Question : <br/>
    <textarea v-model="form.question" rows="5" cols="80%"/><br/>
    Informations particulières (que chatGPT ne peux connaitre): <br/>
    <textarea v-model="form.information" rows="1" cols="80%"/><br/>
    Reformulation : {{form.reformulation}}<br/>
    Alias : <input v-model="form.alias" size=10/><br/>
    Technologie : <select v-model="form.techno_id" name="techno" > <!-- @change="changeSelect" -->
        <option v-for="(t,it) in technos" :key="it" :value="t.id">{{t.name}}</option>
    </select><br/>
    Compétence : <select name="competence" v-model="form.comp" > <!-- @change="changeSelect" -->
        <option v-for="(c,ci) in competences" :key="ci" :value="c.rncp+'-'+c.id">{{c.rncp}}-{{c.id}} : {{c.title}}</option>
    </select><br/>
    Niveau: <select v-model="form.level"><option selected>0</option><option>1</option><option>2</option><option>3</option></select><br/>
    <button ref="checkAnswer"  @click="checkAnswer" class="btn btn-outline-primary buttons">Check answer</button>
    <br/><br/>

    <div v-if="form.explication">
        Réponse : <br/>
        <textarea v-model="form.explication" rows="5" cols="80%"/><br/>
        <div v-if="form.usage">Usage : {{form.usage.total_tokens}} tokens</div>
        <button @click="saveQuestion" :disabled="!form.comp" class="btn btn-outline-primary buttons">Save Question</button>
    </div>

    <hr/>

    Questions disponibles:
<!--
    <table v-if="user">
    <thead>
      <tr><th>Question</th><th>creation</th><th>Compétences</th><th>Techno</th>
        <th v-for="(g,ig) in user.roles" :key="ig">{{g.group.name}} ({{g.group.questions_id.length}})</th>
      </tr>
    </thead>
    <tbody>
    <tr v-for="(q,iq) in questions" :key="iq">
        <td>{{q.question}}</td>
        <td>{{q.createdAt.substr(0,10)}}</td>
        <td>{{q.competences.join(', ')}}</td>
        <td>{{q.techno_id.name}}</td>
        <td v-for="(r,ir) in user.roles" :key="ir">
          <input type="checkbox" v-model="q[r.group_id]" :data-group="r.group._id" :data-groupno="ir" :data-question="q._id" :disabled="q[r.group_id] ? true : null" @change="changeCheckbox"/>
        </td>
    </tr>
    </tbody>
    </table>
-->

    <vue-good-table styleClass="vgt-table condensed striped"  :columns="q_columns" :rows="q_rows">
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field.startsWith('input')">
          <input type='checkbox' :checked="props.row[props.column.field.substr(5,24)]" :disabled="props.row[props.column.field.substr(5,24)]" :data-group="props.column.field.substr(5,24)" :data-question="props.row._id" @change="changeCheckbox"/>
        </div>
      </template>
    </vue-good-table>


    <button @click="saveGroup" :disabled="!enableGroupSave" class="btn btn-outline-primary buttons">Save Group</button>

    <br/><br/>

  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";

export default {
  data(){
      return {
        technos : [],
        form:{},
        //question: '',
        //techno: null,
        //rncp: null,
        competences: [], //{name, title}
        questions:[],
        group: null,
        q_columns: [
            {label: 'Question', field: 'question', html: true,filterOptions:{enabled:true}, },
            {label: 'Alias', field: 'alias', filterOptions:{enabled:true}, },
            {label: 'Date', field: 'date'},
            {label: 'Compétence', field: 'competence', filterOptions:{enabled:true}, },
            {label: 'Niveau', field: 'level', filterOptions:{enabled:true}, },
            {label: 'Techno', field: 'techno', filterOptions:{enabled:true}, },
            ],
        q_rows: [],
        enableGroupSave: false,
      }
  },
  name: "AdminQuestions",
  props: ["user"],
  components: {
  },
  methods: {
    checkAnswer() {
        MyFlashDS.questionCheck(this.form).then(res=>{
                console.log(res.data);
                this.$set(this.form, 'usage', res.data.usage)
                this.$set(this.form, 'explication', res.data.answer)
                this.$set(this.form, 'reformulation', res.data.reformulation)
            })
    },
    changeSelect() { // TODO a faire qu'une fois
        MyFlashDS.questionFindAll({techno_id: this.form.techno_id, competences:this.form.comp, usage:{'$exists':true}}).then(res=>{
            this.questions=res.data;
            this.questions.forEach(q=>{
              this.user.roles.forEach((r, i)=>{
                console.log('Q', i, q._id, 'GRP', r.group_id, r.group.questions_id)
                let f = r.group.questions_id.indexOf(q._id) >= 0;
                q[r.group_id] = f;
                //q['input'+r.group_id] = `${f} <input onclick="changeCheckbox(this)" data-question='${q._id}' data_group='${r.group_id}' data_groupno='${i}' type='checkbox' ${f ? 'checked disabled' : ''}/>`
              })
              q.date = q.createdAt.substr(0,16).replace('T', ' ');
              q.techno = q.techno_id ? q.techno_id.name : '';
              q.competence = q.competences[0];
            })
            this.q_rows = this.questions;
        })
    },
    changeCheckbox(evt) {
      let ds = evt.target.dataset;
      console.log('EVT', evt.target.checked, 'G', ds.group, 'Q', ds.question); // , this.user.roles[ds.groupno].group
      if (evt.target.checked) {
        evt.target.disabled=true;
        let role = this.user.roles.find((r) => r.group_id==ds.group)
        console.log('Role:',role)
        if (role)  role.group.questions_id.push(ds.question)
        this.enableGroupSave=true;
      }
    },
    saveQuestion() {
        MyFlashDS.questionSave(this.form).then(res=>{
          console.log(res.data);
          this.form.question=''
          this.form.explication=''
          this.changeSelect()
        });
    },
    saveGroup() {
        this.user.roles.forEach(r=>{
          console.log('SAVE ROLE', r.group_id, r.group.questions_id)
          MyFlashDS.groupSaveCreate({_id: r.group_id, questions_id:r.group.questions_id}).then(()=>{})
          this.$emit('onAlert', 'info', 'MaJ Groupe '+r.group.name)
        })
/*
        MyFlashDS.groupSaveCreate({_id: this.group._id, questions_id:this.group.questions_id}).then(()=>{
          this.$emit('onAlert', 'info', 'Group mis à jour')
        });
*/
    },
  },
  mounted() {

    MyFlashDS.identifyUser().then(res=>{
      res.data.roles.forEach(r=>{
        console.log('Role', r)
        this.user = res.data;
        this.q_columns.push({label: r.group.name, field: 'input'+r.group_id, html: true})
      })
    })

    MyFlashDS.technoFindAll().then(res=>{
                this.technos=res.data;
            });
    MyFlashDS.rncpFindAll().then(res=>{
            res.data.forEach(rncp=>{
              //console.log("RNCP:", rncp.name, rncp.title);
              rncp.competences.forEach(comp=>{
                //console.log("  comp:", comp.id, comp.title);
                this.competences.push({rncp:rncp.name, id:comp.id, title: comp.title})
              })
            })
                //this.rncp=res.data;
            });
    this.changeSelect();
  },
}
</script>

<style scoped>

.explication {
  color: gray;
  font-size: 75%;
  display: none;
}

table, th, td {
  border: 1px solid black;
}
</style>
