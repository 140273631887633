<template>
  <div class="container justify-content-center">
  <div v-if="c_rows">

    <b-modal ref="imgModal" id="imgModal" @ok="doChangeImage">
        <ImageUpdater @imageChange="imageChange" @imageChangeBin="imageChangeBin"/>
    </b-modal>

    <h2>Groupes</h2>

    <vue-good-table styleClass="vgt-table condensed striped"  :columns="g_columns" :rows="g_rows">
    </vue-good-table>



    <h2>{{(org && org.name) || "Administrateur"}}</h2>

    <button v-if="!org" type="button" @click="org={name:'NewOgr',adress:''}; doSaveOrg()" class="btn btn-outline-danger buttons">{{$t('newOrg')}}</button><br/>


<!--
        <vue-good-table styleClass="vgt-table condensed striped" max-height="500px" :columns="c_columns" :rows="c_rows">
            <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field == 'name'"><a @click="org=props.row" href="#">{{props.row.name}}</a></div>
                <div v-if="props.column.field == 'address'">{{props.row.address}}</div>
                <div v-if="props.column.field == 'users'">
                    <div v-for="(u,idx) in props.row.users" :key="idx">{{u.user_id.email}}  : {{u.role}}
                    </div>
                </div>

                <div v-if="props.column.field == 'rncp_id'">
                       <select v-model="props.row.rncp_id" @change="rncpChange(props.row)">
                         <option/>
                         <option v-for="(rncp,idx) in rncps" :key="idx" :value="rncp.id">{{rncp.name}} {{rncp.title}}</option>
                       </select>
                </div>
                <div v-if="props.column.field == 'techno'">
                  <div v-for="(t,idx) in technos" :key="idx"><input @change="rncpChange(props.row)" v-model="props.row.technos_id" :id="t.name" :name="props.row.name" type="checkbox" :value="t.id"/> <label :title="t.description">{{t.name}}</label></div>
                </div>


                <div v-if="props.column.field == 'image'">
                       <img @click="clickImage(props.index,'image')" :src="props.row.image0"/>
                </div>
                <div v-if="props.column.field == 'bandeau'">
                       <img @click="clickImage(props.index,'bandeau')" :src="props.row.bandeau0"/>
                </div>
                <div v-if="props.column.field == 'isSubscribed'">
                  <input type="checkbox" v-model="props.row.isSubscribed" @change="doUpdRole2(props.row)"/>
                </div>
                <div v-if="props.column.field == 'isCircle'">
                  <input type="checkbox" v-model="props.row.isCircle" @change="doUpdRole2(props.row)"/>
                </div>
                <div v-if="props.column.field == 'url'">
                  <input type="text" v-model="props.row.url" @change="doUpdRole2(props.row)"/>
                </div>
            </template>
        </vue-good-table>
        <br/>
-->

      <div v-if="org">
        <button type="button" @click="doSaveOrg" class="btn btn-outline-primary buttons">{{$t('save')}}</button>
        <br/>
        <!--Organisation: <select v-model="org_idx" @change="orgChange"><option v-for="(o,io) in c_rows" :value="io" :key="io">{{o.name}}</option></select>-->

        nom: <input type="text" placeholder="" size="10" v-model="org.name"/><br/>
        addresse: <input type="text" placeholder="" size="10" v-model="org.address"/><br/>
        Email invitation (templates: &lt;%=email%>, &lt;%=url%>):
        <vue-editor v-model="org.email_invit"/>
<!--
        <h3>Utilisateurs</h3>
        <div v-for="(u,iu) in org.users" :key="iu">
          <div v-if="u.user_id">Email: <b>{{u.user_id.email}}</b>, role: {{u.role}}, connection: {{u.user_id.last_connected}}</div>
        </div>

        <label for="invite">Inviter des étudiants / prof: <button type="button" @click="invite" class="btn btn-outline-primary buttons">{{$t('invite')}}</button></label><br/>
        <textarea id="invite" v-model="org.invite" rows="5" cols="55">emmanuel.Goudot+10@gmail.com</textarea><br/>
-->
        <h3>Classes</h3>
        <button v-if="user && (user.role&1)" type="button" @click="newGroup" class="btn btn-outline-primary buttons">{{$t('newGroup')}}</button>
        <br/>
        Classes: <select v-model="group_idx" @change="changeGrp"><option v-for="(g,ig) in groups" :value="ig" :key="ig">{{g.name}}</option></select><br/>
        <div v-if="group">
            nom: <input type="text" placeholder="" size="10" v-model="group.name"/><br/>
            année: <input type="text" placeholder="" size="10" v-model="group.year"/><br/><br/>

            Lien invitation dans le groupe : <a :href="'../register?group='+this.group._id">https://education.centreIA.fr/register?group={{this.group._id}}</a><br/><br/>

            <Radar v-if="rdata.labels" style="height: 75vh; width: 75vh" :chart-data="rdata" :chart-options="roptions"/>

            technos: {{group.technos_id}}<br/>

            <div class="text-start">
            <div v-for="(t,it) in technos" :key="it">
                <input type="checkbox" @click="technoChange(it)" v-model="t.valid"/> {{t.name}} ({{t.Q}} questions) <span class="desc">({{t.description}})</span><br/>
            </div>
            </div>
        </div>

        <button type="button" @click="doSaveGroup" class="btn btn-outline-primary buttons">{{$t('save')}} ({{group.technos_id.length}} techno)</button>

      </div>

      <br/><br/><br/>

  </div>
  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
import ImageUpdater from '@/components/ImageUpdater.vue'
//import { ref } from 'vue'
import { VueEditor } from "vue2-editor";

import { Radar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, PointElement, LineElement, CategoryScale, LinearScale, RadialLinearScale } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, PointElement, LineElement, CategoryScale, LinearScale, RadialLinearScale)

export default {
  data(){
      return {
        //user: null,
        rncps: [],
        org_idx: 0,
        org: null, //{name:'', address:''},
        groups: [],
        group_idx: 0,
        group: {name:'---', technos_id:[]},
        g_columns: [
            {label: 'Nom', field: 'name', html: true,},
            {label: 'Organisation', field: 'organisation',},
            {label: 'Utilisateurs', field: 'users',},
            {label: 'Questions', field: 'questions',},
            {label: 'Année', field: 'year',},
            {label: 'Date', field: 'created',},
            ],
        g_rows:[],




        technos: [],
        newcust: {},
        c_columns: [
            //{label: this.$i18n.t('id'), field: '_id',},
            {label: this.$i18n.t('name'), field: 'name',},
            {label: this.$i18n.t('address'), field: 'address',},
            {label: this.$i18n.t('admin'), field: 'admin_id',},
            {label: this.$i18n.t('users'), field: 'users',},
            //{label: this.$i18n.t('RNCP'), field: 'rncp_id',},
            //{label: this.$i18n.t('Techno'), field: 'techno',},
            /*
            {label: this.$i18n.t('logo'), field: 'image',},
            {label: this.$i18n.t('bandeau'), field: 'bandeau',},
            {label: this.$i18n.t('isSubscribed'), field: 'isSubscribed',},
            {label: this.$i18n.t('isCircle'), field: 'isCircle',},
            {label: this.$i18n.t('url'), field: 'url',},
            */
            ],
        c_rows:[],
        idx:null, field:null, newImage: null, newImageBin: null, // changement image

        rdata : {
                  labels: ['a','b','c','d'],
                  datasets: [{
                    label: 'E1',
                    data: [0.0,5.0,2.5],
                    fill: true,
/*
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    borderColor: 'rgb(255, 99, 132)',
                    pointBackgroundColor: 'rgb(255, 99, 132)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgb(255, 99, 132)'
*/
                  },{
                                        label: 'E2',
                                        data: [0.0,1.0,2.5],
                                        fill: true,
                                        backgroundColor: '#'+(Math.random()*0xFFFFFF<<0).toString(16)
                                      }]
                },
        roptions : {
            //height: '500px',
            responsive: true,
            maintainAspectRatio: true,
            scales:{r:{pointLabels:{font:{size: 20, color: '#880000'}}}},
            scale: {min: 0.0, max: 5.0,
                },
            },


      }
  },
  name: "AdminOrganisation",
  props: ['user', 'org_id'],
  watch: {
    org_id: function () {
        this.orgChange()
    },
  },
  components: {
    ImageUpdater,
    VueEditor,
    Radar,
  },
  methods: {
    technoChange(it) {
        this.technos[it].valid=!this.technos[it].valid
        this.group.technos_id=[]
        this.technos.forEach(t=>{
            if (t.valid) this.group.technos_id.push(t._id)
        });
        console.log(it, this.technos[it].valid, this.group.technos_id)

    },
    rncpChange(row) {
      //console.log(row)
      console.log(row._id, row.rncp_id.id);
      MyFlashDS.organisationSave({id: row._id, rncp_id:row.rncp_id, technos_id: row.technos_id})
    },
    changeGrp() {
      console.log('changeGrp', this.group_idx, this.groups)
      if (this.groups.length) this.group=this.groups[this.group_idx];
      console.log('  group', this.group)
      console.log('  org', this.org); // NULL ???
      this.group.technos=[]
      this.technos.forEach(t=>{
        t.valid=this.group.technos_id.includes(t._id)
        //this.group.technos.push(this.group.technos_id.includes(x.id))
        //console.log('techno ', t.name, t._id, t.valid)
        })

      if (this.org) this.group.organisation_id = this.org._id;

      console.log('=> users !', this.rdata.labels) // ********************************

      this.rdata.labels = this.group.technos_id.map(id=>this.technos.find(x=>x._id==id).name);

      MyFlashDS.getAllUsers(this.group._id).then(res => {
          console.log(res.data);
          this.rdata.datasets = res.data.map(user => {
            let color = '#'+(Math.random()*0xFFFFFF<<0).toString(16)
            return {
                'label': user.pseudo,
                fill: true,
                //data: [Math.random()*5,Math.random()*5,Math.random()*5],
                data : this.group.technos_id.map(id=>(user.syntheses.find(s=>s.techno_id==id) || {grade:0}).grade),
                backgroundColor: color,
                borderColor: color,
                }})
      })

    },
    doSaveGroup() {
      MyFlashDS.groupSaveCreate(this.group).then(()=>{
      })
    },
    newGroup() {
      let group={name:'Nouvelle', organisation_id:this.org_id}
      MyFlashDS.groupSaveCreate(group).then(()=>{
        this.orgChange()
      });
    },
    doSaveOrg() {
      MyFlashDS.organisationSaveCreate(this.org).then(()=>{
        this.doLoadCustomers();
      })
    },
    orgChange() {
      console.log('AdminOrganisation.orgChange', this.org_id)
        MyFlashDS.organisationFindOne({id: this.org_id}).then(res=>{
            console.log(res)
            this.org=res.data;
            });
      MyFlashDS.groupForOrg(this.org_id).then(res=>{
        console.log(res)
        this.groups=res.data;
        this.group_idx=0;
        this.changeGrp()
        //this.group=this.groups[0]
      });
    },
    doLoadCustomers() {
        console.log('VUE_APP_ROOT_API=', process.env.VUE_APP_ROOT_API)
        MyFlashDS.rncpFindAll().then(res=>{
            this.rncps=res.data;
        })
        MyFlashDS.technoFindAll().then(res=>{
            this.technos=res.data;
            this.orgChange();
        })

        // 2024-10
        MyFlashDS.groupForOrg().then(res=>{
         console.log('GROUP', res.data)
         this.groups = res.data;
         MyFlashDS.getAllUsers().then(res => {
           this.users = res.data;
         })
          this.g_rows = this.groups.map(g=>({
            name: `${g.name} <a href='../register?group=${g._id}'>lien inscription</a>`,
            organisation: g.organisation_id.name,
            questions: g.questions_id.length,
            year: g.year,
            users: 0,
            created: g.createdAt.substr(0,16).replace('T', ' '),
            }))
        })

    },


    doUpdRole2(prop) {
      var role=(prop.isSubscribed?8:0)+(prop.isCircle?4:0);
      console.log('ROLE', {id:prop._id, role:role})
      MyFlashDS.saveCustomer({_id:prop._id, role:role, url:prop.url}).then(() => {
        this.doLoadUsers();
      });
    },
    invite() {
      MyFlashDS.userInvite(this.org).then(()=>{
        this.doLoadCustomers();
      })
    },
    clickImage(idx,field){
      this.idx=idx;
      this.field=field;
      this.$refs.imgModal.show()
    },
    imageChange(data){
      console.log('Admin,imageChange:',data);
      this.newImage=data;
    },
    imageChangeBin(data){
      console.log('Admin,imageChangeBin:',data);
      //this.newImageBin=data;
    },
    doChangeImage() {
        //this.c_rows[this.idx].image=this.newImage;
        this.c_rows[this.idx][this.field]=this.newImage;
            MyFlashDS.saveCustomer(this.c_rows[this.idx]).then(res=>{
                console.log('upload', res);
                this.doLoadCustomers();
            })
    },
  },
  mounted() {
    this.doLoadCustomers();
  },
}
</script>

<style scoped>

button {
  /* width: 20vh; */
}

span.desc {color: gray; font-size: 80%;}

h4 {
  margin-top: 3vh;
}

img {
    max-width: 100px;
    max-height: 100px;
}

</style>
